import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"

const AboutPage = ({ data }) => {
  const consumption = data.allConsumptionJson.edges.map((e) => e.node)
  const years = [...new Set(consumption.map((e) => e.year).filter((e) => e))]

  return (
    <>
      <Layout>
        <h1>Consumption</h1>
        <p>
          This page will summarize (currently work in progress) my historic
          media consumption, starting as far back as I can fairly remember. Just
          a way to neatly organize all the shows and movies I've seen as well as
          books I read at some point. Just because you tend to forget all the
          good stuff. The idea is to also have a brief reflection on each media
          consumed, but that might stay a utopia throughout my lifetime. Or
          priorities change.
        </p>
        <p>
          The content here is sourced from a json file, serving as the database
          of my media consumption. Might actually make more sense to keep a real
          database though, but that will have to wait for a future project.
          Besides, managing a SQL instance for this feels a little over the
          top...
        </p>

        <h2>WIP</h2>
        {consumption
          .filter((e) => !e.year)
          .map((e) => (
            <div key={e.id}>
              <span>[{e.type.charAt(0).toUpperCase() + e.type.slice(1)}]</span>{" "}
              <a href="{e.url}">{e.title}</a>
            </div>
          ))}

        <h2>Books</h2>
        {years.map((year) => (
          <>
            <h3 key={`book${year}`}>{year}</h3>
            {consumption
              .filter((e) => e.type === "book" && e.year === year)
              .map((e) => (
                <div key={e.id}>
                  <a href="{e.url}">{e.title}</a> <span>[{e.author}]</span>{" "}
                  {e.review && <a href="{e.review}">Review</a>}
                </div>
              ))}
          </>
        ))}
        <h2>Movies</h2>
        {years.map((year) => (
          <>
            <h3 key={`movie${year}`}>{year}</h3>
            {consumption
              .filter((e) => e.type === "movie" && e.year === year)
              .map((e) => (
                <div key={e.id}>
                  <a href="{e.url}">{e.title}</a> <span>({e.protagonist})</span>{" "}
                  {e.review && <a href="{e.review}">Review</a>}
                </div>
              ))}
          </>
        ))}
        <h2>Shows</h2>
        {years.map((year) => (
          <>
            <h3 key={`show${year}`}>{year}</h3>
            {consumption
              .filter((e) => e.type === "show" && e.year === year)
              .map((e) => (
                <div key={e.id}>
                  <a href="{e.url}">{e.title}</a> <span>({e.protagonist})</span>{" "}
                  {e.review && <a href="{e.review}">Review</a>}
                </div>
              ))}
          </>
        ))}
      </Layout>
    </>
  )
}

export default AboutPage

export const query = graphql`
  query {
    allConsumptionJson {
      edges {
        node {
          review
          title
          author
          type
          url
          year
          protagonist
        }
      }
    }
  }
`
